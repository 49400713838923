import type { DetailedHTMLProps, ImgHTMLAttributes } from "react";
import avatarOfFrostJpeg from "../assets/AvatarOfFrost.jpg";
import avatarOfFrostWebp from "../assets/AvatarOfFrost.webp";

export const AvatarOfFrost = (
  props: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
) => {
  return (
    <picture>
      <source srcSet={avatarOfFrostWebp.src} type="image/webp" />
      <img
        {...props}
        src={avatarOfFrostJpeg.src}
        width={avatarOfFrostJpeg.width}
        height={avatarOfFrostJpeg.height}
        alt="Avatar of Frost"
      />
    </picture>
  );
};
