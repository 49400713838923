import { useCallback, useMemo } from "react";
import { useLocalStorage } from "../../lib/localStorage";
import { assertNever } from "../../lib/utils";
import { Theme } from "./Theme";

const validThemes = new Set(Object.values(Theme));

function isValidTheme(value: unknown): value is Theme {
  return typeof value === "string" && validThemes.has(value as any);
}

export function useStoredTheme(): [Theme, (theme: Theme) => void] {
  const [value, setValue] = useLocalStorage("theme");

  const theme = useMemo(
    () => (isValidTheme(value) ? value : Theme.SystemDefault),
    [value],
  );
  const setTheme = useCallback(
    (theme: Theme) => {
      switch (theme) {
        case Theme.Dark:
        case Theme.Light:
          return setValue(theme);
        case Theme.SystemDefault:
          return setValue(undefined);
        default:
          throw assertNever(theme);
      }
    },
    [setValue],
  );

  return [theme, setTheme];
}
